import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\app-router.js");
;
import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\client-page.js");
;
import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\error-boundary.js");
;
import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\layout-router.js");
;
import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "D:\\Webroot\\dop-mono-v3.36.3\\.yarn\\__virtual__\\next-virtual-bb7972c3c8\\0\\cache\\next-npm-14.2.3-7770cdfada-666c977020.zip\\node_modules\\next\\dist\\client\\components\\render-from-template-context.js");
